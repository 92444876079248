import React from "react";
import { Select } from "antd";
import { getCurrentEnv, setCurrentEnv } from "util/Api";
import { apiEnvDict } from "../../util/Api";

const renderOption = (envItem) => (
  <Select.Option key={envItem.value} value={envItem.value}>
    {envItem.title}
  </Select.Option>
);

export const ApiSwitcher = () => (
  <Select defaultValue={getCurrentEnv()} onChange={setCurrentEnv}>
    {apiEnvDict.map(renderOption)}
  </Select>
);
