import { ROLES } from "../util/AccessControl";

const createSection = (
  key,
  intlTitleId,
  items = [],
  defaultAccessParams = { visibleFor: [] }
) => {
  return {
    key,
    intlTitleId,
    items: items.map((item) =>
      item?.visibleFor ? item : { ...item, ...defaultAccessParams }
    ),
    ...defaultAccessParams,
  };
};

const GeneralSection = createSection(
  "general",
  "sidebar.general",
  [
    {
      key: "dashboard",
      iconClassName: "icon icon-dashboard",
      intlTitleId: "sidebar.dashboard",
      componentName: "DashboardPage",
    },
  ],
  {
    visibleFor: [ROLES.support, ROLES.developer, ROLES.leader, ROLES.system],
  }
);

const AccountManagementSection = createSection(
  "account_management",
  "sidebar.account_management",
  [
    {
      key: "accounts",
      iconClassName: "icon icon-auth-screen",
      intlTitleId: "sidebar.accounts",
      componentName: "AccountsPage",
    },
    {
      key: "lists",
      iconClassName: "icon icon icon-calendar",
      intlTitleId: "sidebar.lists",
      componentName: "ListsPage",
    },
  ],
  {
    visibleFor: [ROLES.support, ROLES.leader, ROLES.developer, ROLES.system],
  }
);

const UserManagementSection = createSection(
  "users_management",
  "sidebar.users_management",
  [
    {
      key: "support-users",
      iconClassName: "icon icon-avatar -flex-column-reverse",
      intlTitleId: "sidebar.support_users",
      componentName: "SupportUsersPage",
    },
    {
      key: "developer-users",
      iconClassName: "icon icon-avatar -flex-column-reverse",
      intlTitleId: "sidebar.developer_users",
      componentName: "DeveloperUsersPage",
    },
    {
      key: "users",
      iconClassName: "icon icon-avatar -flex-column-reverse",
      intlTitleId: "sidebar.users",
      componentName: "UsersPage",
    },
    {
      key: "reset-user",
      iconClassName: "icon icon-avatar -flex-column-reverse",
      intlTitleId: "sidebar.reset-user",
      componentName: "ResetAccountPage",
    },
  ],
  {
    visibleFor: [ROLES.support, ROLES.leader, ROLES.system],
  }
);

const EventRegistrationManagementSection = createSection(
  "event_registration_management",
  "sidebar.event_registration_management",
  [
    {
      key: "user-registrations",
      iconClassName: "icon icon-contacts",
      intlTitleId: "sidebar.eventRegistrations",
      componentName: "UserRegistrationsPage",
    },
    {
      key: "user-edit-event",
      iconClassName: "icon icon-feedback",
      intlTitleId: "sidebar.eventEdit",
      componentName: "EditEventPage",
    },
    {
      key: "user-edit-registration-form",
      iconClassName: "icon icon-feedback",
      intlTitleId: "sidebar.registrationForm",
      componentName: "EditRegistrationFormPage",
    },
    {
      key: "user-edit-template",
      iconClassName: "icon icon-feedback",
      intlTitleId: "sidebar.templateEdit",
      componentName: "EditTemplatePage",
    },
    {
      key: "user-edit-account",
      iconClassName: "icon icon-feedback",
      intlTitleId: "sidebar.accountEdit",
      componentName: "EditAccountPage",
    },
    {
      key: "session-promotion-statistics",
      iconClassName: "icon icon-chart",
      intlTitleId: "sidebar.session-promotion-statistics",
      componentName: "SessionPromotionStatisticsPage",
    },
  ],
  {
    visibleFor: [ROLES.support, ROLES.leader, ROLES.system],
  }
);

const SystemMonitoringSection = createSection(
  "system_monitoring",
  "sidebar.system-monitoring",
  [
    {
      key: "system-statistics-history",
      iconClassName: "icon icon-chart",
      intlTitleId: "sidebar.history",
      componentName: "SystemStatisticsHistoryPage",
    },
    {
      key: "system-statistics-live-status",
      iconClassName: "icon icon-chart-line",
      intlTitleId: "sidebar.live-status",
      componentName: "SystemLiveStatusPage",
    },
  ],
  {
    visibleFor: [ROLES.support, ROLES.developer, ROLES.leader, ROLES.system],
  }
);

const SystemMonitoringLogsSection = createSection(
  "system_logs",
  "sidebar.system-monitoring-logs",
  [
    {
      key: "system-statistics-logs",
      iconClassName: "icon icon-select",
      intlTitleId: "sidebar.system-logs",
      componentName: "SystemLogsPage",
    },
  ],
  {
    visibleFor: [ROLES.developer],
  }
);

const MailingMonitoringSection = createSection(
  "mailing_monitoring",
  "sidebar.mailing-monitoring",
  [
    {
      key: "sending-statistics",
      iconClassName: "icon icon-chart",
      intlTitleId: "sidebar.sending-statistics",
      componentName: "SendingStatisticsPage",
    },
    {
      key: "zero-bounce-status",
      iconClassName: "icon icon-chart",
      intlTitleId: "sidebar.zero-bounce-status",
      componentName: "ZeroBouncePage",
    },
  ],
  {
    visibleFor: [ROLES.support, ROLES.leader, ROLES.system],
  }
);

const DataSection = createSection(
  "data",
  "sidebar.data",
  [
    {
      key: "events",
      iconClassName: "icon icon-calendar",
      intlTitleId: "sidebar.events",
      componentName: "EventsPage",
    },
    {
      key: "attendees",
      iconClassName: "icon icon-contacts",
      intlTitleId: "sidebar.attendees",
      componentName: "AttendeesPage",
    },
    {
      key: "documents",
      iconClassName: "icon icon-copy",
      intlTitleId: "sidebar.documents",
      componentName: "DocumentsPage",
    },
    {
      key: "exhibitions",
      iconClassName: "icon icon-icon",
      intlTitleId: "sidebar.exhibitions",
      componentName: "ExhibitionsPage",
    },
    {
      key: "locations",
      iconClassName: "icon icon-navigation",
      intlTitleId: "sidebar.locations",
      componentName: "LocationsPage",
    },
    {
      key: "meetings",
      iconClassName: "icon icon-chat-bubble -flex-column-reverse",
      intlTitleId: "sidebar.meetings",
      componentName: "MeetingsPage",
    },
    {
      key: "posts",
      iconClassName: "icon icon-feedback",
      intlTitleId: "sidebar.posts",
      componentName: "PostsPage",
    },
    {
      key: "sessions",
      iconClassName: "icon icon-widgets",
      intlTitleId: "sidebar.sessions",
      componentName: "SessionsPage",
    },
    {
      key: "streams",
      iconClassName: "icon icon-steps",
      intlTitleId: "sidebar.streams",
      componentName: "StreamsPage",
    },
    {
      key: "tracks",
      iconClassName: "icon icon-timeline-new",
      intlTitleId: "sidebar.tracks",
      componentName: "TracksPage",
    },
    {
      key: "templates",
      iconClassName: "icon icon-card",
      intlTitleId: "sidebar.templates",
      componentName: "TemplatesPage",
    },
  ],
  {
    visibleFor: [ROLES.developer, ROLES.leader, ROLES.system, ROLES.support],
  }
);

const AutomationManagementSection = createSection(
  "automation_management",
  "sidebar.automation_management",
  [
    {
      key: "automations",
      iconClassName: "icon icon-cards-list-view",
      intlTitleId: "sidebar.automations",
      componentName: "AutomationsPage",
    },
  ],
  {
    visibleFor: [ROLES.developer, ROLES.leader, ROLES.system, ROLES.support],
  }
);

const BookingSystemSection = createSection(
  "booking_system",
  "sidebar.booking_system",
  [
    {
      key: "bookings",
      iconClassName: "icon icon-copy",
      intlTitleId: "sidebar.bookings",
      componentName: "BookingsPage",
    },
    {
      key: "tickets",
      iconClassName: "icon icon-copy",
      intlTitleId: "sidebar.tickets",
      componentName: "TicketsPage",
    },
    {
      key: "options",
      iconClassName: "icon icon-copy",
      intlTitleId: "sidebar.options",
      componentName: "OptionsPage",
    },
    {
      key: "discounts",
      iconClassName: "icon icon-copy",
      intlTitleId: "sidebar.discounts",
      componentName: "DiscountsPage",
    },
    {
      key: "payment_accounts",
      iconClassName: "icon icon-copy",
      intlTitleId: "sidebar.payment_accounts",
      componentName: "PaymentAccountsPage",
    },
  ],
  {
    visibleFor: [ROLES.developer, ROLES.leader, ROLES.system, ROLES.support],
  }
);

const JobsSection = createSection(
  "jobs_management",
  "sidebar.jobs_management",
  [
    {
      key: "jobs/scheduled",
      iconClassName: "icon icon-schedule",
      intlTitleId: "sidebar.scheduled",
      componentName: "ScheduledJobsPage",
    },
    {
      key: "jobs/failed",
      iconClassName: "icon icon-down",
      intlTitleId: "sidebar.failed",
      componentName: "FailedJobsPage",
    },
  ],
  {
    visibleFor: [ROLES.developer, ROLES.leader, ROLES.system, ROLES.support],
  }
);

const SystemSection = createSection(
  "system",
  "sidebar.system",
  [
    {
      key: "query",
      iconClassName: "icon icon-crm",
      intlTitleId: "sidebar.query",
      componentName: "QueryPage",
    },
  ],
  {
    visibleFor: [ROLES.developer, ROLES.leader, ROLES.system, ROLES.support],
  }
);

export const MenuSections = [
  GeneralSection,
  AccountManagementSection,
  UserManagementSection,
  EventRegistrationManagementSection,
  SystemMonitoringSection,
  SystemMonitoringLogsSection,
  MailingMonitoringSection,
  DataSection,
  AutomationManagementSection,
  BookingSystemSection,
  JobsSection,
  SystemSection,
];

export const MenuItems = MenuSections.reduce(
  (acc, cur) => [...acc, ...cur.items],
  []
);
